.fixed-header {
	width: 100%;
	height: 80px;
	position: fixed;
	top: 0px;
	background-color: #e8e8e8;
	z-index: 50;
	box-shadow: 20px 10px 40px rgba(0, 0, 0, 0.6);
}

.center-container {
	margin: 0px 30px 0 30px !important;
	min-width: 1287px;
	padding-top: 80px;
}
.center-container.more-padding {
	padding-top: 160px;
}

.App-header {
	width: 100%;
	height: 80px;
	position: fixed;
	top: 0px;
	background-color: #e8e8e8;
	z-index: 50;
	box-shadow: 20px 10px 40px rgba(0, 0, 0, 0.6);
}
.App-header-container {
	display: flex;
	width: 100%;
	height: 100%;
}
.header-logo-container {
	width: 21%;
	display: flex;
	height: 78%;
	margin-top: auto;
}
.counter-container {
	min-width: 14%; /*140px;*/
	height: 100%;
	padding: 5px;
}
.year-container {
	width: 8%; /*100px;*/
	height: 100%;
	/*padding: 5px;*/
}
.chat-icon-container {
	height: 100%;
	min-width: 90px;
	display: flex;
}

.counter-container .bg-red {
	height: 100%;
	/*background: rgb(0,41,85);
  background: linear-gradient(135deg, rgba(0,41,85,1) -20%, rgba(145,0,39,1) 100%);*/
	border-radius: 10px;
	display: flex;
}
.counter-container .bg-red .row,
.year-container .bg-blue .row,
.player-name-container .bg-green .row,
.change-team-name-container .bg-blue .row {
	height: 50%;
}
.counter-container .bg-red .row .col,
.year-container .bg-blue .row .col,
.player-name-container .bg-green .col,
.change-team-name-container .bg-blue .col {
	margin: auto 0;
}

.year-container .header-section {
	color: #0a2955;
	font-weight: 700;
	text-align: center;
	font-size: 16px;
	line-height: 70px;
	margin-right: 10px;
}
.year-container .bg-blue {
	height: 100%;
	/*background: rgb(0,41,85);
  background: linear-gradient(135deg, rgba(0,41,85,1) -20%, rgba(33,146,64,1) 100%);*/
	border-radius: 10px;
	display: flex;
}
.year-value {
	font-size: 18px;
	font-weight: 900;
	color: #0a2955;
	text-align: center;
	line-height: 70px;
}

.counter-container .header-section {
	color: #0a2955;
	font-weight: 500;
	text-align: center;
	font-size: 13px;
}
.counter-title {
	font-size: 15px;
	color: #910027;
	text-align: center;
}
.counter-value {
	font-size: 18px;
	font-weight: 900;
	color: #0a2955;
	text-align: center;
}

.change-team-name-container .team-data-content {
	margin: auto 0;
	width: 100%;
}
.change-team-name-container .form .team-data-content {
	margin: 0px !important;
}

.year-container .bg-blue.data {
	display: flex;
	height: 60%;
}
.year-container .team-data-content,
.counter-container .team-data-content,
.player-name-container .team-data-content {
	margin: auto 0;
	width: 100%;
}

.change-team-name-container .header-section {
	color: #0a2955;
	font-weight: 500;
	text-align: left;
	font-size: 13px;
}

.change-team-name-container .bg-blue {
	height: 100%;
	/*background: rgb(0,41,85);
  background: linear-gradient(135deg, rgba(0,41,85,1) -20%, rgba(90,172,255,1) 100%);*/
	border-radius: 10px;
	display: flex;
}
.change-team-name-container .bg-blue.form {
	height: 100% !important;
}
.widget-content {
	width: 100%;
	margin: auto;
}

.player-name-container {
	min-width: 8% /*100px*/;
	padding: 5px;
}
.player-name-container .header-section {
	color: #0a2955;
	font-weight: 500;
	text-align: left;
	font-size: 13px;
}
.player-name-container .bg-green {
	height: 100%;
	/*background: rgb(0,41,85);
  background: linear-gradient(135deg, rgba(0,41,85,1) -20%, rgba(90,172,255,1) 100%);*/
	border-radius: 10px;
	display: flex;
}

.cursorPointer {
	cursor: pointer;
}

.team-form-container {
	display: flex;
	height: 100%;
}

.team-name-title {
	color: #ffffff;
	text-align: end;
}
.player-name {
	text-align: left;
	color: #0a2955;
	font-weight: 900;
	font-size: 18px;
}

.header-separator {
	background-color: #cacaca;
	width: 2px;
	height: 40px;
	margin: auto 10px auto 0px;
}
.header-separator-2 {
	background-color: #cacaca;
	width: 2px;
	height: 40px;
	margin: auto 0px auto 0px;
}

.header-logo-container-2 {
	margin: 0 auto;
	display: flex;
}

.zoom-container {
	margin: auto 10px;
	min-width: 80px;
}
.zoom-btn-container {
	display: flex;
	cursor: pointer;
	height: 100%;
}
.zoom-btn-container .zoom-btn {
	width: 25px;
	height: 25px;
	margin: auto;
}
.zoom-btn-container .text {
	color: #002955;
	text-align: center;
	margin-left: 5px;
	font-size: 15px;
}

.view-only {
	color: #0a2955;
	font-weight: 900;
	text-align: center;
	width: 100%;
	font-size: 20px;
}

.kit-menu-btn {
	width: 25px;
	cursor: pointer;
	margin-right: 20px;
}
.chat-container {
	display: flex;
	cursor: pointer;
}
.chat-container img {
	height: 20px;
	width: 20px;
	margin: auto 0;
}
.chat-container .chat-title {
	font-size: 16px;
	color: #002955;
	font-weight: bold;
	text-align: center;
	margin: auto 5px;
	line-height: 70px;
}

.App-header .container,
.App-header .row,
.App-header .col {
	height: 100%;
}
.partner-logo {
	width: 11%;
	margin: 10px;
	display: flex;
	height: 78%;
}

.logout-btn-container {
	margin: 0 1%;
	display: flex;
	height: 100%;
}
.logout-btn {
	color: #ffffff;
	width: 36px;
	height: 36px;
	text-align: center;
	line-height: 80px;
	cursor: pointer;
	font-size: 12px;
	/*margin: 0 auto;*/
}
.language-btn {
	color: #0a2955;
	line-height: 80px;
	cursor: pointer;
	font-size: 14px;
	width: 80px;
	font-weight: bold;
}
.language-btn:hover {
	text-decoration: underline;
}
.logout-btn img {
	width: 100%;
	height: 100%;
}
.undo-btn {
	background-color: #0a2955;
	color: #ffffff;
	font-weight: bold;
	width: 100px;
	height: 36px;
	border-radius: 18px;
	text-align: center;
	line-height: 36px;
	cursor: pointer;
	font-size: 10px;
	margin: 0 auto;
}
.Team-name {
	font-size: 16px;
	color: #ffffff;
	font-weight: bold;
	text-align: center;
	cursor: pointer;
}
.change-team-name-container {
	min-width: 10%; /*180px;*/
	padding: 5px;
}
.change-team-name-container input {
	width: 90%;
	margin: auto;
}
.change-team-name-container .team-name-text {
	font-size: 18px;
	color: #0a2955;
	text-align: left;
	font-weight: 900;
}
.change-team-name-container .team-name-btn {
	background-color: #0a2955;
	color: #ffffff;
	font-weight: bold;
	width: 100px;
	height: 30px;
	border-radius: 15px;
	text-align: center;
	line-height: 2.5em;
	cursor: pointer;
	font-size: 12px;
	margin: 0 auto;
}

.kits-objects-btn {
	background-color: #e8e8e8;
	height: 100%;
	width: 30px;
	position: fixed;
	left: 0px;
	top: 0px;
	bottom: 0px;
	z-index: 100;
	box-shadow: 20px 10px 40px rgba(0, 0, 0, 0.5);
	cursor: pointer;
}
.kits-objects-btn .button {
	/*position: relative;
    top: 50%;*/
	transform: rotate(180deg);
	writing-mode: vertical-lr;
	cursor: pointer;
	/*margin-top: -70px;
    display: flex;*/
	display: block;
	text-align: center;
	height: 100%;
}
.kits-objects-btn .button img {
	width: 30px;
	height: 28px;
	transform: rotate(180deg);
	margin: 5px auto;
	display: inline-block;
}
.kits-objects-btn .button .text {
	text-align: center;
	/*line-height: 30px;*/
	color: #0a2955;
	margin: 0px auto;
	display: inline-block;
	vertical-align: middle;
}
.kitContainer,
.fieldProperties {
	position: fixed;
	top: 0px;
	bottom: 0px;
	left: -200px;
	width: 200px;
	z-index: 99;
	background-color: #e8e8e8;
	overflow: hidden;
	transition: left 0.3s ease;
	overflow-x: hidden;
	overflow-y: scroll;
}
.kitContainer.fullWidth,
.fieldProperties.fullWidth {
	left: 30px;
	box-shadow: 20px 10px 40px rgba(0, 0, 0, 0.6);
}
.filesContainer.fullWidth,
.checkListContainer.fullWidth {
	right: 30px;
	box-shadow: 20px 10px 40px rgba(0, 0, 0, 0.6);
}
.files-library-btn {
	background-color: #e8e8e8;
	height: 100%;
	width: 30px;
	position: fixed;
	right: 0px;
	top: 0px;
	bottom: 0px;
	z-index: 100;
	box-shadow: -20px 10px 40px rgba(0, 0, 0, 0.5);
	cursor: pointer;
	display: flex;
	flex-direction: column;
}
.files-library-btn .section {
	height: 50%;
	display: flex;
}
.files-library-btn .button {
	/*position: relative;
    top: 50%;*/
	transform: rotate(180deg);
	writing-mode: vertical-lr;
	cursor: pointer;
	/*margin-top: -70px;*/
	display: block;
	text-align: center;
	margin: auto;
}
.files-library-btn .button img {
	width: 24px;
	height: 22px;
	transform: rotate(180deg);
	margin: 5px auto;
	display: inline-block;
}
.files-library-btn .button.checklist img {
	width: 26px;
	transform: rotate(180deg);
	margin: 5px auto;
	display: inline-block;
}
.files-library-btn .button .text {
	text-align: center;
	/*line-height: 30px;*/
	margin: 0px auto;
	color: #0a2955;
	display: inline-block;
	vertical-align: middle;
}
.filesContainer,
.checkListContainer {
	position: fixed;
	top: 0px;
	bottom: 0px;
	right: -350px;
	width: 350px;
	z-index: 99;
	background-color: #e8e8e8;
	overflow: hidden;
	transition: right 0.3s ease;
	overflow-x: hidden;
	overflow-y: scroll;
}

.files-header {
	height: 80px;
	width: 100%;
	border-bottom: 1px solid #555a6e;
	display: flex;
}
.files-header-logo {
	width: 135px;
	display: flex;
	margin: auto;
}
.files-header-logo img {
	width: 24px;
	height: 22px;
	transform: rotate(90deg);
	margin: auto 0;
}
.files-header-logo .text {
	text-align: center;
	line-height: 80px;
	margin: auto 10px;
	color: #0a2955;
}

.moveLeft {
	margin-left: 230px !important;
	min-width: 1300px;
}

.moveRight {
	margin-right: -195px !important;
	right: 40px !important;
}

.dragging {
	opacity: 0.5;
}
.containerDraggable {
	width: 123px;
	height: 60px;
	margin: 0 auto;
}
.tokenDraggable {
	width: 74px;
	height: 45px;
	margin: 0 auto;
}

.containerDraggable img,
.tokenDraggable img {
	width: 95%;
	height: 95%;
	cursor: grab;
}

.blur {
	/*min-height: 100%;*/
	width: 100%;
}
.kits-header {
	height: 80px;
	width: 100%;
	border-bottom: 1px solid #555a6e;
	display: flex;
}
.kits-header-logo {
	/*min-width: 125px;*/
	display: flex;
	margin: auto;
	align-items: center;
	justify-content: center;
}
.kits-header-logo img {
	transform: rotate(90deg);
	width: 30px;
	height: 28px;
}
.kits-header-logo .text {
	text-align: center;
	margin: auto 5px;
	color: #0a2955;
	font-size: 13px;
}

.object-container-div {
	margin-bottom: 10px;
	padding-left: 10px;
	padding-right: 10px;
}
.object-coins-div,
.object-pins-div,
.object-labels-div,
.object-starting-kits-div,
.object-tokens-div,
.object-delete-div {
	/*padding: 10px;*/
	/*margin-top: 10px;*/
	margin-bottom: 10px;
	padding-left: 10px;
	padding-right: 10px;
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
}

.object-labels-div .col {
	padding-left: 15px !important;
}

.object-delete-div .container-text {
	float: left;
}

.object-value {
	float: right;
	min-width: 25px;
	height: 24px;
	border-radius: 12px;
	background-color: #0a2955;
	color: white;
	text-align: center;
	line-height: 1em;
	margin-top: 10px;
	padding: 5px;
	font-size: 14px;
}
.object-value-center {
	width: 25px;
	height: 24px;
	border-radius: 12px;
	background-color: #0a2955;
	color: white;
	text-align: center;
	line-height: 1em;
	padding: 5px;
	font-size: 14px;
	margin: 0 auto;
}

.container-text {
	font-size: 15px;
	color: #002955;
	line-height: 3em;
}

.draggable-coins-container {
	width: 50px;
	height: 50px;
	margin: 0 auto;
}
.draggable-coins {
	width: 100%;
	height: 100%;
	cursor: grab;
}
.draggable-pin-container {
	width: 40px;
	height: 40px;
	margin: 0 auto;
}
.draggable-pin {
	width: 100%;
	height: 100%;
}

.labelDraggable {
	width: 48px;
	height: 48px;
	margin: 0 auto;
}
.labelDraggable img {
	width: 100%;
	height: 100%;
	cursor: grab;
}

.kits-separator {
	background-color: #555a6e;
	height: 1px;
	width: 100%;
}

.labelDraggable {
	width: 48px;
	height: 48px;
	margin: 0 auto;
}
.labelDraggable img {
	width: 100%;
	height: 100%;
	cursor: grab;
}
.draggable-pins-container {
	display: flex;
}
.pinDraggable {
	width: 30px;
	/*height: 48px;*/
	margin: 0 auto;
}
.pinDraggable img {
	width: 100%;
	height: 100%;
	cursor: grab;
}
.object-container-div {
	margin-bottom: 10px;
	padding-left: 10px;
	padding-right: 10px;
}
.object-coins-div,
.object-pins-div,
.object-labels-div,
.object-starting-kits-div,
.object-tokens-div,
.object-delete-div {
	/*padding: 10px;*/
	/*margin-top: 10px;*/
	margin-bottom: 10px;
	padding-left: 10px;
	padding-right: 10px;
}

.object-labels-div .col {
	padding-left: 15px !important;
}

.object-delete-div .container-text {
	float: left;
}

.object-value {
	float: right;
	min-width: 25px;
	height: 24px;
	border-radius: 12px;
	background-color: #0a2955;
	color: white;
	text-align: center;
	line-height: 1em;
	margin-top: 10px;
	padding: 5px;
	font-size: 14px;
}

.removeDroppable {
	width: 30px;
	height: 30px;
	/*margin: 0 auto;*/
	float: right;
	margin-top: 8px;
}
.removeDroppable img {
	width: 100%;
	height: 100%;
}

.checkListTitle {
	background-color: #0a2955;
	padding: 5px;
	color: white;
	font-size: 14px;
	font-weight: 900;
}
.checkListRow {
	padding-left: 5px;
	padding-right: 5px;
}
.checkListSubtitle {
	font-size: 12px;
	color: #313131;
	font-weight: 600;
}
.checkListValue {
	font-size: 10px;
	color: #555555;
	white-space: pre-line;
	line-height: 10px;
	margin-bottom: 5px;
	margin-top: 5px;
}

.checkListRow ul {
	padding-inline-start: 20px;
}
.checkListRow ul li {
	font-size: 12px;
}

.phaseSeparator {
	margin-bottom: 1rem;
}
.phaseLineSeparator {
	height: 1px;
	background-color: #ff0000;
	width: 100%;
}
.phaseText {
	font-size: 10px;
	color: red;
	font-weight: 800;
}
.coins-container .helper {
	display: inline-block;
	height: 100%;
	vertical-align: middle;
}

.coins-container .coins:nth-child(2) {
	margin-left: -1px;
}
.coins-container .coins:nth-child(3) {
	margin-left: -7px;
}
.coins-container .coins:nth-child(4) {
	margin-left: -7px;
}
.coins-container .coins:nth-child(5) {
	margin-left: -7px;
}
.coins-container .coins:nth-child(6) {
	margin-left: -7px;
}
.coins-container .coins:nth-child(7) {
	margin-left: -1px;
}
.coins-container .coins:nth-child(8) {
	margin-left: -7px;
}
.coins-container .coins:nth-child(9) {
	margin-left: -7px;
}
.coins-container .coins:nth-child(10) {
	margin-left: -7px;
}
.coins-container .coins:nth-child(11) {
	margin-left: -7px;
}
.coins-container .coins:nth-child(12) {
	margin-left: -1px;
}
.coins-container .coins:nth-child(13) {
	margin-left: -7px;
}
.coins-container .coins:nth-child(14) {
	margin-left: -7px;
}
.coins-container .coins:nth-child(15) {
	margin-left: -7px;
}
.coins-container .coins:nth-child(16) {
	margin-left: -7px;
}
.coins-container .coins:nth-child(17) {
	margin-left: -1px;
}
.coins-container .coins:nth-child(18) {
	margin-left: -7px;
}
.coins-container .coins:nth-child(19) {
	margin-left: -7px;
}
.coins-container .coins:nth-child(20) {
	margin-left: -7px;
}
.coins-container .coins:nth-child(21) {
	margin-left: -7px;
}

.Token-container-draggable {
	background-color: #bc1700;
	width: 100%;
	height: 100%;
	width: 74px;
	height: 45px;
	color: white;
	margin: 0;
	cursor: grab;
}
.Token-container-draggable.black {
	background-color: #000000 !important;
}
.Token-container-draggable .Token-value {
	height: 60%;
	font-size: 16px;
	font-weight: bolder;
	text-align: center;
	line-height: 2em;
}
.Token-container-draggable .Token-text {
	height: 40%;
	font-size: 5px;
	text-align: center;
	font-weight: bold;
}
.Token-container-draggable.black .Token-value {
	color: #f0dba6;
}
.Token-container-draggable.black .Token-text {
	color: #f0dba6;
	font-size: 6px !important;
}

.files-section {
	width: 100%;
}
.filesListTitle {
	background-color: #0a2955;
	padding: 5px;
	color: white;
	font-size: 14px;
	font-weight: 900;
}
.fileRow {
	min-height: 40px;
	border-bottom: 1px solid #555a6e;
}
.fileRow .file-link {
	padding: 15px;
}
.fileRow .file-link a {
	color: #002955;
	font-size: 14px;
}

.noPaddingLeftRight {
	padding-left: 0px !important;
	padding-right: 0px !important;
}
.App-logo {
	width: -webkit-fill-available;
	max-width: 205px;
}

button#dropdown-basic {
	background: 0;
	border: 0;
	margin: 0;
	padding: 0;
}
button#dropdown-basic:hover,
button#dropdown-basic:active,
button#dropdown-basic:focus,
.btn-primary.focus,
.btn-primary:focus {
	color: #0a2955 !important;
	border: 0 !important;
	outline: 0 !important;
	box-shadow: 0 0 0 0.2rem rgb(38 143 255 / 0%) !important;
}
