.left-bar {
	width: 135px;
	margin-left: 20px;

	padding-bottom: 18px;
	margin-right: 35px;
}
.left-bar > div {
	padding: 2px 5px;
	border: 2px solid #bfc5c9;
}
.teamscore {
	text-align: center;
	font-size: 15px;
	color: white;
	font-weight: 400;
	padding-bottom: 5px;
}

.side-bar-block {
	font-family: "TradeGothicBold";
	text-align: center;
	position: relative;
	background-color: white;
	height: 59px;
	width: 85px;
	margin: 5px auto;
	display: flex;
	align-items: center;
}

.side-bar-block > div {
	display: flex;
    justify-content: center;
    /* grid-column-gap: 7px; */
    column-gap: 7px;
    /* margin: 5px auto; */
    align-items: center;
    flex: 1;
    height: 100%;
}

.side-bar-block svg {
	width: 28px;
	height: 23px;
}
.invested > .side-bar-block span {
	font-size: 8px;
	color: white;
	position: absolute;
	bottom: 0;
	width: 100%;
	left: 0;
	margin-bottom: -12px;
}
.invested > div:nth-of-type(2) {
	border: 5px solid white;
	border-bottom: 14px solid white !important;
	background: #bfc5c9 !important;
}
.invested > div:nth-of-type(2) > span {
	color: black;
}
.invested > div:nth-of-type(2) div > svg > g > g > path {
	fill: white;
}
.invested > div:nth-of-type(3) {
	border: 5px solid #d01349;
	border-bottom: 14px solid #d01349 !important;
}
.invested > div:nth-of-type(3) div > svg > g > g > path {
	fill: #d01349;
}
.invested > div:nth-of-type(4) {
	border: 5px solid #00a84f;
	border-bottom: 14px solid #00a84f !important;
}
.invested > div:nth-of-type(4) div > svg > g > g > path {
	fill: #00a84f;
}
.invested > div:nth-of-type(5) {
	border: 5px solid #005b96;
	border-bottom: 14px solid #005b96 !important;
}
.invested > div:nth-of-type(5) div > svg > g > g > path {
	fill: #005b96;
}
.invested > div:nth-of-type(6) {
	border: 5px solid #fcb813;
	border-bottom: 14px solid #fcb813 !important;
}
.invested > div:nth-of-type(6) div > svg > g > g > path {
	fill: #fcb813;
}
.invested > div:nth-of-type(7) {
	border: 5px solid white;
	border-bottom: 14px solid white !important;
}
.invested > div:nth-of-type(7) > div > span {
	color: #899196;
}
.invested > div:nth-of-type(7) > div {
	border: 2px solid rgb(137, 145, 150);
	height: 42px;
	width: 72px;
}
.invested > div:nth-of-type(7) div > svg > g > g > path {
	fill: #899196;
}
.invested > div:nth-of-type(8) {
	border: 5px solid #8e9396;
	border-bottom: 14px solid #8e9396 !important;
}
.invested > div:nth-of-type(8) div > svg > g > g > path {
	fill: #8e9396;
}
.invested > div:nth-of-type(9) {
	border: 5px solid #8c5f86;
	border-bottom: 14px solid #8c5f86 !important;
}
.invested > div:nth-of-type(9) div > svg > g > g > path {
	fill: #8c5f86;
}

.team-score-badge {
	zoom: 0.35;
	margin-top: 40px;
}

@-moz-document url-prefix() {
	.team-score-badge {
		-moz-transform: scale(0.35) translate(-170px, 120px);
		margin-top: -70px;
	}
}

.team-score-value {
	position: absolute;
	top: 6px;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	color: darkred;
	font-size: 25px;
	font-weight: 600;
	z-index: 5;
}
.d-grid {
	display: grid;
}

.cards-moveLeft {
	margin-left: 188px;
}
