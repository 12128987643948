body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
		"Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
		"Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #212529;
	text-align: left;
	background-color: #fff;
}
*,
::after,
::before {
	box-sizing: border-box;
}
/* Home Page */
.form-body {
	height: 100%;
}
.form-body > .row {
	position: relative;
	margin-left: 0;
	margin-right: 0;
	height: 100%;
	display: flex;
	flex-wrap: wrap;
}
.img-holder {
	width: 550px;
	background-color: #000;
}
.img-holder {
	display: inline-block;
	position: absolute;
	top: 0;
	left: 0;
	width: 550px;
	min-height: 700px;
	height: 100%;
	overflow: hidden;
	background-color: #000000;
	/* padding: 60px; */
	text-align: center;
	z-index: 999;
}
.img-holder .bg {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	opacity: 1;
	background-image: url(../images/img1.jpg);
	background-size: cover;
	background-position: center;
	z-index: -1;
}
.img-holder .info-holder {
	position: relative;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}
.form-holder {
	margin-left: 550px;
}
.form-holder {
	width: 100%;
	background: white;
}
.form-holder .form-content {
	position: relative;
	text-align: center;
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	justify-content: center;
	-webkit-align-items: center;
	align-items: center;
	padding: 60px;
	min-height: 100%;
}
.form-content .form-items {
	max-width: 340px;
	text-align: left;
}
.form-content .form-items {
	display: inline-block;
	width: 100%;
	max-width: 340px;
	text-align: left;
	-webkit-transition: all 0.4s ease;
	transition: all 0.4s ease;
}
.form-content .form-items {
	max-width: 340px;
	text-align: left;
}
.form-content .form-items {
	display: inline-block;
	width: 100%;
	max-width: 340px;
	text-align: left;
	-webkit-transition: all 0.4s ease;
	transition: all 0.4s ease;
}
.website-logo-inside {
	margin-bottom: 20px;
}
.form-content h3 {
	color: #0a2955;
	text-align: left;
}
.form-content h3 {
	text-align: left;
	font-size: 24px;
	font-weight: 900;
	margin-bottom: 10px;
}
.website-logo-inside a {
	display: inline-block;
}
.website-logo-inside .logo {
	background-image: url(../images/logo-dark3.svg);
}
.website-logo-inside .logo {
	display: inline-block;
	background-image: url(../images/logo-light2.svg);
	background-size: contain;
	background-repeat: no-repeat;
}
.website-logo-inside .logo img.logo-size {
	opacity: 0 !important;
}
.website-logo-inside .logo img {
	width: 250px;
}

.relativeContainer {
	height: 100%;
}

.form-content p {
	color: #000;
	text-align: left;
}
.form-content p {
	font-size: 18px;
	font-weight: 300;
	line-height: 20px;
	margin-bottom: 30px;
}

.AuthorizationContainer {
	width: 100%;
	height: 100%;
}
.NotAuthorizedContainer {
	position: absolute;
	top: 50%;
	left: 50%;
	margin-top: -25px;
	margin-left: -250px;
	width: 500px;
	height: 50px;
	text-align: center;
}
.NotAuthorizedContainer .NotAuthorizedText {
	color: #002955;
	font-weight: 700;
}

.NotAuthorizedContainerLoaderImg {
	position: absolute;
	top: 50%;
	left: 50%;
	margin-top: -100px;
	margin-left: -250px;
	width: 500px;
	height: 200px;
	text-align: center;
}
.loaderContainer {
	width: 200px;
	height: 200px;
	margin: 0 auto;
}
.loaderContainer img {
	width: 100%;
	height: 100%;
}

.coins-sum {
	position: absolute;
	right: 3px;
	color: white;
	font-weight: 600;
	font-size: 12px;
	top: 0;
	text-align: center;
	animation: show 300ms ease-in;
	animation-fill-mode: forwards;
	z-index: 3;
	-webkit-text-stroke: 0.1px white;
	background: #335a97;
}

@keyframes show {
	0% {
		display: none;
		opacity: 0;
	}
	1% {
		display: block;
	}
	100% {
		display: block;
		opacity: 1;
	}
}
