.circle {
	width: 232px;
	height: 220px;
	border-radius: 60%;
	font-family: "TradeGothicBold";
	position: absolute;
	left: auto;
	z-index: 2;
	justify-content: center;
	display: flex;
	align-items: center;
}

.circle > div {
	margin-top: 10px;
}
.rectangle {
	min-height: 27px;
	background-color: white;
	margin: 2px auto;
	padding: 2px 4px;
	display: flex;
	flex-direction: column;
	font-size: 0.4em;
	width: 159px;
	position: relative;
}
.rectangle > div {
	display: flex;
}
.rectangle > span {
	padding-left: 10px;
}
.three-bars {
	display: flex;
	grid-column-gap: 12px;
	column-gap: 12px;
	padding: 4px;
	justify-content: space-between;
}

.three-bars > div {
	display: flex;
	flex-direction: column;
	color: white;
	text-align: center;
	font-size: 7px;
	width: 100%;
	position: relative;
}
.three-bars > div > div {
	background-color: white;
	height: 75px;
	width: 40px;
	display: grid;
	align-content: space-around;
	justify-content: center;
}
.three-bars > div > div > svg {
	width: 25px;
	height: 25px;
	margin: auto;
}

.checked {
	background-color: white !important;
}

.svgContainer.TeamLeader,
.svgContainer.Blue,
.svgContainer.Red,
.svgContainer.Yellow,
.svgContainer.White,
.svgContainer.Green {
	width: 25px;
	margin: auto;
}
.svgContainer.TeamLeader svg,
.svgContainer.Blue svg,
.svgContainer.Red svg,
.svgContainer.Yellow svg,
.svgContainer.White svg,
.svgContainer.Green svg {
	width: 25px;
	height: 25px;
	margin: auto;
}
.svgContainer.TeamLeader svg > g > g > * {
	fill: #899196 !important;
}
.svgContainer.Blue svg > g > g > * {
	fill: #005b96 !important;
}
.svgContainer.Red svg > g > g > * {
	fill: #d01349 !important;
}
.svgContainer.Yellow svg > g > g > * {
	fill: #fcb813 !important;
}
.svgContainer.White svg > g > g > * {
	fill: #899196 !important;
}
.svgContainer.Green svg > g > g > * {
	fill: #00a94f !important;
}
