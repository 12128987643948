.bg {
	position: fixed;
	top: 0;
	left: 0;

	min-width: 100%;
	min-height: 100%;
	z-index: -1;
}
.wrapper {
	width: 90%;
	display: flex;
	justify-content: center;
	/*zoom: 0.9;*/
}
.containers {
	position: relative;
	width: 1121px;
}

.relationship-card {
	padding: 36px 3px;
	height: 103px;
	font-family: "TradeGothicBold";
	text-align: center;
	position: relative;
	margin-bottom: 5px;
	background-color: white;
	display: flex;
	column-gap: 10px;
	border: 7px solid #bfc5c9;
	z-index: 1;
}
.relationship {
	position: absolute;
}
.relationship::after {
	width: 5px;
	height: 273px;
	background: transparent;
	border: 2px solid;
	content: "";
	position: absolute;
	left: 50%;
	top: 50px;
	z-index: 0;
	transform-origin: top center;
}
.relationship::before {
	bottom: 50%;
	width: 5px;
	border: 2px solid;
	z-index: 0;
	height: 203px;
	background: transparent;
	content: "";
	position: absolute;
	left: 50%;
	margin-bottom: 3px;
	transform-origin: bottom center;
}

.relationship-card > span {
	font-size: 7px;
	color: black;
	background-color: #bfc5c9;
	position: absolute;
	bottom: 1px;
	width: 43px;
	left: -1px;
	margin-bottom: -6px;
	text-align: start;
	padding-top: 1px;
}

.right-arrow {
	margin-left: -13px;
	transform: rotateY(180deg);

	height: 14px;
	margin-right: -9px;
}
.left-arrow {
	height: 9px;
	margin-left: -9px;
}

.rings {
	position: absolute;
	bottom: 3px;
	right: -58px;
	width: 98%;
}
.ring {
	width: 9px;
	height: 9px;
	border-radius: 100%;
	border: 1px solid;
	position: relative;
}
.relation-value {
	position: absolute;
	top: 4px;
	font-size: 20px;
	width: 100%;
	left: 0;
}

.happy-face {
	width: 20px;
	position: absolute;
	top: -6px;
	left: -7px;
}


.zoom-50 {
	zoom: 50%;
	-moz-transform: scale(0.5);
}
.zoom-60 {
	zoom: 60%;
	-moz-transform: scale(0.6);
}
.zoom-70 {
	zoom: 70%;
	-moz-transform: scale(0.7);
}
.zoom-80 {
	zoom: 80%;
	-moz-transform: scale(0.8);
}
.zoom-90 {
	zoom: 90%;
	-moz-transform: scale(0.9);
}
.zoom-100 {
	zoom: 100%;
	-moz-transform: scale(1);
}
.zoom-110 {
	zoom: 110%;
	-moz-transform: scale(1.1) translate(5%, 5%);
}
.zoom-120 {
	zoom: 120%;
	-moz-transform: scale(1.2) translate(10%, 10%);
}
.zoom-130 {
	zoom: 130%;
	-moz-transform: scale(1.3) translate(15%, 15%);
}
.zoom-140 {
	zoom: 140%;
	-moz-transform: scale(1.4) translate(20%, 20%);
}
.zoom-150 {
	zoom: 150%;
	-moz-transform: scale(1.5) translate(25%, 25%);
}

.probability-card img {
	width: 32px;
	height: 32px;
}
