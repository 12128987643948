@font-face {
  font-family: "TradeGothicBold";
  src: url("../fonts/TradeGothicBoldTwo.ttf") format("truetype");
}
@font-face {
  font-family: "Netto";
  src: url("../fonts/netto.ttf") format("truetype");
}


.three-bars .impact-increase {
  display: flex;
  color: white;
  font-size: 8px;
  background-color: green;
  border-radius: 9px;
  width: 18px;
  height: 18px;
  position: absolute;
  top: -5px;
  right: -5px;
  align-items: center;
  justify-content: center;
}
.three-bars .impact-decrease {
  display: flex;
  color: white;
  font-size: 8px;
  background-color: red;
  border-radius: 9px;
  width: 18px;
  height: 18px;
  position: absolute;
  top: -5px;
  right: -5px;
  align-items: center;
  justify-content: center;
}