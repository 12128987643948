.event-modal > .modal-dialog {
	max-width: 70vw !important;
	margin-top: 80px;
}
.event-modal > .modal-dialog > .modal-content {
	padding: 10px;
	font-size: 0.8em;
	min-height: 400px;
}
.event-container > div.grid-div {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	background: #edf0f6;
}

.event-header {
	background: #c0cdde;
	padding: 5px;
	margin-bottom: 10px;
	font-weight: 700;
	font-size: 16px;
	box-shadow: 0px 2px 5px #888888;
	white-space: pre-line;
}

.event-wrapper {
	width: 100%;
	overflow-x: auto;
}

.event-container > div > div:nth-of-type(2),
.event-container > div > div:nth-of-type(4) {
	border-right: 1px dotted black;
	border-left: 1px dotted black;
}
.option2 {
	box-shadow: 0px 0px 2px 0px grey;
	border-bottom: 1px solid black;
	margin-bottom: -3px;
	background: #edf0f6;
}
.event-container > div:nth-of-type(3) {
	text-align: center;
}

.event-container > div > div {
	padding: 5px 5px;
	flex: 1
}
.event-container > div > div > div {
	display: flex;
	justify-content: space-between;
}
.section-title {
	justify-content: center !important;
}

.event-option-wrapper {
	cursor: pointer;
}
.event-option-wrapper:hover {
	background-color: #c0cdde;
}
.event-option-wrapper.selected {
	background-color: #c0cdde;
}

.event-option-title {
	font-size: 15px;
	font-weight: 700;
}
.event-option-desc {
	font-size: 15px;
}

.arrow {
	width: 18px;
	height: 18px;
}
.up {
	transform: rotateX(180deg);
}
.positive,
.negative {
	display: grid;
	grid-template-columns: auto auto;
	width: 43px;
}
.negative {
	color: #c93500;
}
.positive {
	color: #009051;
}
.borderTop {
	border-top: 1px solid;
}

.emojis {
	display: flex;
	width: 100px;
	justify-content: space-between;
	margin-bottom: 5px;
	margin-top: -1px;
}
.emojis > svg {
	width: 15px;
	height: 15px;
}
.emojis > svg:nth-of-type(1) > path {
	fill: #009051;
}
.emojis > svg:nth-of-type(2) > path {
	fill: #c93500;
}

.time-coins-wrapper {
	margin-bottom: 20px;
}

.time-coins-title {
	margin-top: 3px;
	background: #edf0f6;
	padding: 10px;
}
.time-coins-title .noBorder {
	border: none !important;
}

.slide-wrapper {
}
.slide-header-content {
	background-color: #c0cdde;
	padding: 25px;
}
.slide-body-content {
	padding: 25px;
}
.slide-body-content img {
	max-width: 100%;
}

.probability-container {
	background: #edf0f6;
	display: flex;
}

.wholeSpace {
	flex: 0 0 100%;
}
.successful-container {
	display: flex;
	background: #edf0f6;
	/*margin-top: 3px;*/
}
.successful-container > div {
	width: 100%;
	border: 0 !important;
}
.successful-container > div:first-of-type {
	border-right: 1px dotted black !important;
}
.successful-container > div > div {
	display: block !important;
}
.successful-container > div > div > div/*:nth-child(n + 2)*/ {
	display: flex;
	justify-content: space-between;
}

.card-grid .probability-card {
	background-color: #002858;
	color: #fff;
	text-align: center;
	height: 80px;
	width: 70px;
	border: 1px solid #fff;
	border-radius: 5px;
	font-size: 28px;
	font-weight: 600;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 20%;
	cursor: pointer;
}
.probability-card:hover {
	border: 2px solid #fff;
	opacity: 0.9;
}
.probability-card.show {
	opacity: 1 !important;
}
.probability-card.disabled {
	cursor: initial !important;
	opacity: 0.4;
}
.probability-card.disabled :hover {
	opacity: 1 !important;
}
.card-grid .probability-card.selected {
	background-color: #fff;
	color: #005b98;
	font-size: 50px;
	font-weight: 700;
	opacity: 1;
}
.probability-card.clicked {
	border: 4px solid #fdba0b;
}
.selected {
	background-color: #fdba0b;
	border-radius: 5px;
}

.emojis > img {
	width: 20px;
	height: 20px;
}

.react-tiny-popover-container {
	background-color: #1c3d5a;
	border-radius: 2px;
	box-shadow: 0 2px 4px 0 rgb(0 0 0 / 10%);
	width: 191px;
	font-size: 13px;
	padding: 8px;
	color: #ffffff;
	z-index: 3;
	position: absolute;
	top: 90px;
	left: -40px;
	text-align: left;
}
.react-tiny-popover-container-money {
	background-color: #1c3d5a;
	border-radius: 2px;
	box-shadow: 0 2px 4px 0 rgb(0 0 0 / 10%);
	width: 191px;
	font-size: 13px;
	padding: 8px;
	color: #ffffff;
	z-index: 3;
	position: absolute;
	top: -7px;
	left: 90px;
	text-align: left;
}

.nextBtnContainer {
	border-radius: 5px !important;
	height: 35px;
	width: 80px;
	float: right;
}


.stickyHeader {
	width: 345px;
	position: sticky;
	top: 0px;
	left: calc(50% - 172.5px);
	text-align: center;
}

.choice-result-container {
	background: #edf0f6;
	padding-bottom: 25px;
}

.impact-wrapper {
	width: 345px;
	display: flex;
	flex-direction: column;
	position: sticky;
    top: 0px;
    left: calc(50% - 172.5px);
}

.result-impact-row {
	display: flex;
	justify-content: space-between;
	border-right: none !important;
    border-left: none !important;
	
}